export const TRANSLATION_COMPONENTS = {
    sup: <sup />,
    small: <small />,
    p: <p />,
    ul: <ul />,
    li: <li />,
    ol: <ol />,
    strong: <strong />,
    span: <span />,
    a: <a />,
    em: <em />,
    br: <br />
};

export const CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY = 'additionalData';
export const CURRENT_REFERER_STORAGE_KEY = 'currentReferrer';

const EXCLUSION_PATHS = [
    '/about',
    '/press',
    '/contact-us',
    '/referrals',
    '/404',
    '/careers',
    '/invites',
    '/careers',
    '/educators',
    '/product-education',
    '/activation',
    '/unsupported',
    '/terms/terms-of-service',
    '/terms/terms-of-service-original',
    '/terms/privacy',
    '/terms/privacy-original',
    '/feedback',
    '/getstarted-honeybook',
    '/client-relationships'
];

export const PROMO_POPUP_EXCLUSION_PATHS = [
    ...EXCLUSION_PATHS,
    '/',
    '/getstarted'
];

export const PROMO_BANNER_EXCLUSION_PATHS = [...EXCLUSION_PATHS];

export const VARIANT_OPTIONS = ['a', 'b', 'c', 'd', 'e', 'f'];

export const INITIAL_DOLLAR_AMOUNT = 14123;

export const CAPTERRA_REVIEWS = {
    rating: 4.8,
    totalReviews: 598,
    bestRating: '5',
    worstRating: '1',
    url: 'https://www.capterra.com/p/162588/HoneyBook/'
};
export const G2_REVIEWS = {
    rating: 4.5,
    totalReviews: 158,
    bestRating: '5',
    worstRating: '1',
    url: 'https://www.g2.com/products/honeybook/reviews'
};

export const INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE = {
    imageUrl:
        'https://res.cloudinary.com/honeybook/image/upload/v1693999725/marketing_site/email-signature/signature-image-empty-state.png',
    name: 'Gunther Anders',
    jobTitle: 'Founder',
    company: 'Gunther’s Barista Academy',
    email: 'gunther@gunthersbaristas.com',
    address: '199 Lafayette St, New York, NY 10012, USA',
    phone: '210-559-2358',
    website: 'https://www.gunthersbaristas.com',
    facebookUrl: '_',
    twitterUrl: '_',
    linkedinUrl: '_',
    instagramUrl: '_',
    pinterestUrl: '_',
    youtubeUrl: '_',
    selectedTemplateName: 'conservative'
};

export const REFERRAL_DISCOUNT_KEY = 'referralDiscount';

export const CTA_COPY_TEST_PAGE_LIST = [
    'online-contract',
    'get-document-signing',
    'proposal-software',
    'online-invoices',
    'industry-caterers',
    'contracts-for-event-and-wedding-planners',
    'industry-event-planners',
    'industry-venue-managers',
    'invoice-template',
    'online-payment-software',
    'meeting-scheduler',
    'client-portal-software',
    'automations',
    'honeybook-vs-17hats',
    'honeybook-vs-bonsai',
    'honeybook-vs-dubsado',
    'honeybook-vs-freshbooks',
    'features',
    'getstarted',
    'canada',
    'get-started-crm',
    'crm-for-freelancers',
    'software-for-band-managers',
    'contracts-for-cinematographers',
    'industry-cinematographers',
    'dj-booking-software',
    'all-in-one-software-for-estheticians-and-makeup-artists',
    'industry-wedding-planners',
    'industry-florists',
    'all-in-one-software-for-hair-stylists',
    'all-in-one-software-for-estheticians-and-makeup-artists',
    'all-in-one-software-for-officiants',
    'all-in-one-software-for-photo-booth-rental-companies',
    'industry-photographers',
    'photography-contract-template',
    'free-invoice-templates/photographers',
    'all-in-one-software-for-event-rental-companies',
    'industry-videographers',
    'contracts-for-videographers',
    'all-in-one-software-for-wedding-photographers',
    'free-invoice-templates',
    'get-invoice-templates',
    'mission-statement',
    'honeybook-vs-monday',
    'software-for-home-accountants',
    'software-for-cleaning-business',
    'free-invoice-templates/cleaners',
    'contractor-billing-software',
    'software-for-copywriters',
    'software-for-dog-trainers',
    'all-in-one-software-for-group-practice',
    'all-in-one-software-for-lawyers',
    'industry-freelancers',
    'freelance-consulting-contract-template',
    'all-in-one-software-for-private-practice',
    'software-for-pr-companies',
    'real-estate-crm-software',
    'all-in-one-software-for-teachers',
    'free-invoice-templates/tutors',
    'software-for-visual-artists',
    'all-in-one-software-for-yoga-studios',
    'honeybook-vs-pipedrive',
    'getstarted',
    'tools/self-employment-tax-calculator',
    'industry-consultants',
    'software-for-agencies',
    'industry-marketing',
    'industry-consultants',
    'software-for-online-coaching',
    'free-invoice-templates/coach',
    'industry-consultants',
    'freelance-consulting-contract-template',
    'free-invoice-templates/consultants',
    'industry-consultants',
    'freelance-consulting-contract-template',
    'free-invoice-templates/consultants',
    'industry-creative-designer',
    'designer-contract-template',
    'free-invoice-templates/designers',
    'industry-designers',
    'designer-contract-template',
    'free-invoice-templates/designers',
    'doula-business-software',
    'free-invoice-templates/doula',
    'software-for-agencies',
    'industry-freelancers',
    'crm-for-graphic-designers',
    'freelance-consulting-contract-template',
    'health-coach-software',
    'free-invoice-templates/coach',
    'industry-social-media',
    'industry-interior-design',
    'designer-contract-template',
    'free-invoice-templates/interior-design',
    'life-coach-software',
    'free-invoice-templates/coach',
    'industry-marketing',
    'industry-consultants',
    'industry-social-media',
    'virtual-assistant-software',
    'free-invoice-templates/virtual-assistant',
    'software-for-web-designers',
    'free-invoice-templates/designers',
    'honeybook-vs-vcita',
    'automations'
];
