const TableContents = [
    {
        id: 'clientFlow',
        features: [
            {
                id: 'unlimitedClients',
                cellContents: [true, true, true]
            },
            {
                id: 'pipeline',
                cellContents: [true, true, true]
            },
            {
                id: 'contactForms',
                cellContents: ['contactForms1', 'contactForms3', 'unlimited']
            },
            {
                id: 'leadForms',
                cellContents: ['leadForms2', 'leadForms10', 'unlimited']
            },
            {
                id: 'gmail',
                cellContents: [true, true, true]
            },
            {
                id: 'invoices',
                cellContents: [true, true, true]
            },
            {
                id: 'contracts',
                cellContents: [true, true, true]
            },
            {
                id: 'brochures',
                cellContents: [true, true, true]
            },
            {
                id: 'proposals',
                cellContents: [true, true, true]
            },
            {
                id: 'service',
                cellContents: [true, true, true]
            },
            {
                id: 'forms',
                cellContents: [true, true, true]
            },
            {
                id: 'portal',
                cellContents: [true, true, true]
            },
            {
                id: 'calendar',
                cellContents: [true, true, true]
            },
            {
                id: 'ios',
                cellContents: [true, true, true]
            }
        ]
    },
    {
        id: 'scheduling',
        features: [
            {
                id: 'scheduler',
                cellContents: [
                    'oneSession',
                    'unlimitedSessions',
                    'unlimitedSessions'
                ]
            },
            {
                id: 'schedulePay',
                cellContents: [
                    'oneSession',
                    'unlimitedSessions',
                    'unlimitedSessions'
                ]
            },
            {
                id: 'autoConfirm',
                cellContents: [true, true, true]
            },
            {
                id: 'roundRobin',
                cellContents: [false, true, true]
            }
        ]
    },
    {
        id: 'productivity',
        features: [
            {
                id: 'tasks',
                cellContents: [true, true, true]
            },
            {
                id: 'timeTracking',
                cellContents: [true, true, true]
            },
            {
                id: 'automations',
                cellContents: [false, true, true]
            },
            {
                id: 'companies',
                cellContents: [false, false, true]
            }
        ]
    },

    {
        id: 'payments',
        features: [
            {
                id: 'customPayments',
                cellContents: [true, true, true]
            },
            {
                id: 'recurringInvoices',
                cellContents: [true, true, true]
            },
            {
                id: 'autoReminders',
                cellContents: [true, true, true]
            },
            {
                id: 'autopay',
                cellContents: [true, true, true]
            },
            {
                id: 'discounts',
                cellContents: [true, true, true]
            },
            {
                id: 'instantDeposits',
                cellContents: [true, true, true]
            },
            {
                id: 'fraud',
                cellContents: [true, true, true]
            },
            {
                id: 'dispute',
                cellContents: [true, true, true]
            },
            {
                id: 'tipping',
                cellContents: [true, true, true]
            },
            {
                id: 'acceptCard',
                cellContents: ['startingAt', 'startingAt', 'startingAt']
            },
            {
                id: 'acceptBank',
                cellContents: [
                    'oneFivePercent',
                    'oneFivePercent',
                    'oneFivePercent'
                ]
            }
        ]
    },
    {
        id: 'integrations',
        features: [
            {
                id: 'email',
                cellContents: [true, true, true]
            },
            {
                id: 'calendarIntegration',
                cellContents: [true, true, true]
            },
            {
                id: 'quickbooksIntegration',
                cellContents: [false, true, true]
            },
            {
                id: 'zapier',
                cellContents: [false, true, true]
            },
            {
                id: 'meta',
                cellContents: [false, true, true]
            },
            {
                id: 'zoom',
                cellContents: [false, true, true]
            },
            {
                id: 'calendly',
                cellContents: [false, true, true]
            }
        ]
    },
    {
        id: 'honeybookAi',
        features: [
            {
                id: 'priorityLead',
                cellContents: ['limited', true, true]
            },
            {
                id: 'leadEnrichment',
                cellContents: ['limited', true, true]
            },
            {
                id: 'writingAssistant',
                cellContents: ['limited', true, true]
            },
            {
                id: 'meetingPrep',
                cellContents: ['limited', true, true]
            },
            {
                id: 'meetingSummary',
                cellContents: ['limited', true, true]
            },
            {
                id: 'projectPrep',
                cellContents: ['limited', true, true]
            }
        ]
    },
    {
        id: 'communication',
        features: [
            {
                id: 'templates',
                cellContents: [true, true, true]
            },
            {
                id: 'templateGallery',
                cellContents: [true, true, true]
            },
            {
                id: 'autoEmail',
                cellContents: [true, true, true]
            },
            {
                id: 'receipts',
                cellContents: [true, true, true]
            },
            {
                id: 'sms',
                cellContents: [false, true, true]
            }
        ]
    },
    {
        id: 'branding',
        features: [
            {
                id: 'customBranding',
                cellContents: [true, true, true]
            },
            {
                id: 'removeHB',
                cellContents: [false, true, true]
            },
            {
                id: 'fonts',
                cellContents: [false, true, true]
            }
        ]
    },
    {
        id: 'finances',
        features: [
            {
                id: 'paymentTracking',
                cellContents: [true, true, true]
            },
            {
                id: 'capital',
                cellContents: [true, true, true]
            },
            {
                id: 'expenses',
                cellContents: [false, true, true]
            },
            {
                id: 'profit',
                cellContents: [false, true, true]
            },
            {
                id: 'reports',
                cellContents: ['basic', 'standard', 'advanced']
            }
        ]
    },
    {
        id: 'teams',
        features: [
            {
                id: 'participants',
                cellContents: [true, true, true]
            },
            {
                id: 'members',
                cellContents: [false, 'upTo2', 'unlimited']
            },
            {
                id: 'roles',
                cellContents: [false, true, true]
            },
            {
                id: 'assignTasks',
                cellContents: [false, true, true]
            }
        ]
    },
    {
        id: 'support',
        features: [
            {
                id: 'personalizedSupport',
                cellContents: [true, true, true]
            },
            {
                id: 'fileSetup',
                cellContents: [true, true, 'priority']
            },
            {
                id: 'priority',
                cellContents: [false, false, true]
            }
        ]
    }
];

export default TableContents;
